.item-thumbnail {
  width: fit-content;
  margin: 10px auto;
  text-align: center;
  padding: 8px;
  border-radius: 3px;
  min-width: 130px;
  border: 1px solid transparent;
  .placeholder {
    width: 151px;
    height: 106px;
    display: flex;
    align-items: center;
    border: solid 1px #979797;
    margin: 0 auto;
  }
  .vertical {
    width: 106px;
    height: 151px;
  }
  p {
    margin: 2px;
  }
  &:hover {
    .placeholder {
      box-shadow: 0 0 13px 0 #87ceeb;
    }
  }
  .loading-img {
    width: 40px;
    margin: auto;
    margin-top: 20px !important;
  }
  .image {
    margin: auto;
    canvas {
      border: 1px solid #d9d9d9;
    }
  }
}
.item-thumbnail:hover {
  box-shadow: 0px 0px 5px 0px #3785e8;
}
.active {
  border: 1px solid #5f91ff !important;
}
.selected {
  background: #a6cbff;
}
