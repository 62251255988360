.search-pn {
  margin: 0 8px;
  .header {
    height: 40px;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
  }
  .ant-tabs-nav {
    margin: 0 0 8px 0;
  }
  .search-result-checkbox span:last-child {
    display: flex;
  }
}
