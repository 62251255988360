.tabfile {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
}
.vtabfile {
  max-height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
  position: absolute;
  top: 41px;
  right: 0;
}
.tab-close:hover {
  color: #4096ff;
}
.tab-close {
  padding: 0px 8px;
  cursor: pointer;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.red-progress-bar {
  height: 100%;
  width: 100%;
  background: red;
  position: absolute;
  bottom: 0px;
}
.blue-progress-bar {
  height: 100%;
  width: 100%;
  background: rgb(22, 119, 255);
  position: absolute;
  bottom: 0px;
}
.ocr-progress-bar {
  width: 100%;
  position: relative;
  height: 6px;
}
.tabfile-content {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
}
.tabfile-name {
  flex: 1 1 0%;
  padding: 0px 8px;
  height: 100%;
  align-content: center;
  white-space: nowrap;
  overflow: hidden;
}
.tabfile-content.selected {
  background: #a6cbff;
}
