#thumbnail_container-duplicate {
  .item-thumbnail-duplicate {
    width: 158px;
    float: left;
    margin: auto;
    text-align: center;
    border: 1px solid transparent;
  }
  .thumnail-img {
    align-content: center;
    text-align: center;
    padding-top: 8px;
    canvas {
      border: 1px solid #636262;
    }
  }
}
