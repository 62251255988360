.menu-icon {
  height: 69px;
  box-sizing: border-box;
  width: 100%;
  padding: 8px 0;
  border-bottom: 1px solid #ddd;
}
.menu-icon .separator {
  float: left;
  height: 50px;
  width: 1px;
  background: #ddd;
  margin: 0 6px;
}
.menu-icon .selected {
  background: #a6cbff;
}
