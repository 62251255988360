.top-menu-strip {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
  background-color: #e8e8e8;
  height: 40px;
  border-bottom: 1px solid #d9d9d9;
}
.topmenuitem {
  display: flex;
  min-width: 170px;
}
.topmenuitem .label {
  flex: 1;
}
