.tag-form .header {
  width: 100%;
  display: flex;
  height: 43px;
  align-items: center;
  padding: 0 10px;
  border-bottom: 1px solid #edebe9;
  box-sizing: border-box;
  cursor: move;
}
.react-draggable {
  background: #fff;
  position: absolute;
  width: 250px;
  z-index: 10;
  box-shadow: 1px 1px 5px 1px #201f1e;
}
