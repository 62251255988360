.toc-panel {
  .row,
  .header {
    display: flex;
    align-items: center;
  }
  .header-panel {
    height: 40px;
    border-bottom: 1px solid #d9d9d9;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    padding-left: 8px;
  }
  .page {
    min-width: 55px;
  }
  .header .cell {
    padding: 0 4px;
    box-sizing: border-box;
  }
  .row .cell {
    padding: 0 4px;
  }
  .group-cell {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  input {
    height: 22px;
    border-radius: 2px;
  }
  .border-top {
    border-top: 1px solid #d9d9d9;
  }

  .border-bottom {
    border-bottom: 1px #d9d9d9 solid;
  }

  .border-right {
    border-right: 1px solid #d9d9d9;
  }

  .border-left {
    border-left: 1px solid #d9d9d9;
  }
  .ant-tree .ant-tree-treenode {
    padding: 0 0 0px 0;
    border-bottom: 1px #d9d9d9 solid;
  }
  .ant-input {
    padding: 4px 4px;
  }
  .ant-tree .ant-tree-indent-unit {
    display: inline-block;
    width: 8px;
  }
  .ant-tree .ant-tree-treenode-draggable .ant-tree-draggable-icon {
    width: 15px;
  }
  .ant-tree .ant-tree-switcher {
    width: 15px;
  }
  .rct-tree-root {
    padding: 0;
  }
  .rct-tree-items-container {
    list-style-type: none;
  }
  ul[role="group"] {
    padding-left: 10px;
  }
  .rct-tree-items-container li[aria-selected=true]>div:first-child {
    background-color: #a8d3f3;
  }
}
