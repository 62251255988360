.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.ant-dropdown-trigger,
.ant-dropdown-trigger:hover,
.ant-dropdown-trigger:focus,
.ant-dropdown-trigger:active {
  color: #000;
}
.ant-btn,
.ant-dropdown-menu-title-content,
.ant-tabs,
.ant-radio + span,
.ant-checkbox + span,
.ant-typography,
label,
.ant-input-number,
.ant-input,
.ant-tabs-tab,
.ant-alert-description,
.ant-table-cell,
.ant-select-selector,
.ant-select-item,
.ant-col,
.rc-tabs,
.ant-modal-body,
.ant-tree-title,
.contexify,
.ant-empty-description {
  font-size: 12px !important;
}

.rc-tabs {
  border: none !important;
}
.rc-tabs-nav-list {
  border-top: none !important;
}
.rc-tabs-ink-bar {
  background: #1677ff !important;
}
.rc-tabs-left .rc-tabs-nav,
.rc-tabs-right .rc-tabs-nav {
  min-width: 40px !important;
}
.ant-color-picker-trigger .ant-color-picker-color-block {
  width: 100% !important;
}
.ant-alert-warning {
  align-items: center;
}
.ant-alert-with-description {
  padding: 8px 12px;
}
.ant-select-single {
  height: 28.2px;
}
.contexify_item:not(.contexify_item-disabled):focus > .contexify_itemContent,
.contexify_item:not(.contexify_item-disabled):hover > .contexify_itemContent {
  background-color: #1677ff !important;
}
.header-panel {
  height: 40px;
  border-bottom: 1px solid #d9d9d9;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}
.left-panel-container {
  margin: 0 8px;
  height: 100%;
}
.contexify[role="menu"] {
  z-index: 1031;
}
.rc-tabs-content-holder {
  width: calc(100% - 40px);
}
