.btn-icon-menu {
  min-width: 55px;
  height: 53px;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
  display: block;
  padding: 4px;
  text-align: center;
  margin: 0 4px;
  box-sizing: border-box;
  float: left;
  color: #333!important;
}
.btn-icon-menu:hover {
  background: #e2e2e2;
}
.btn-icon-menu img {
  width: 25px;
}
